import React from 'react'
import ImageMeta from '../ImageMeta'

const QuoteCard = ({ quote, name, image  }) => {
    return (
        <div className="quote-card">
            <div className="quote-card__quote">
                <ImageMeta
                    publicId="SIX/DEV/quotation-marks"
                    cloudName="nuvolum"
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                />
                <p>{quote}</p>
            </div>
            <div className="quote-card__person">
                <ImageMeta
                    publicId={image}
                    cloudName="nuvolum"
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                />
                <p>{name}</p>
            </div>
        </div>
    )
}

export default QuoteCard
