import React, { useRef } from "react"
import Slider from "react-slick"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import QuoteCard from "./QuoteCard"

const QuoteSlider = ({ quotes }) => {
  const sliderRef = useRef(null)

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    variableWidth: true,
    appendDots: (dots) => (
      <div>
        <div
          className="slick-arrow left-arrow"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <FontAwesomeIcon icon={faChevronLeft} color="white" />
        </div>
        <ul style={{ margin: "0px" }}>
          {dots.map((item, index) => {
            return (
              <li
                key={index}
                aria-label={`move to carousel slide number ${index + 1}`}
                role="button"
                className={item.props.className}
                tabIndex="0"
              >
                {item.props.children}
              </li>
            )
          })}
        </ul>
        <div
          className="slick-arrow right-arrow"
          onClick={() => sliderRef.current.slickNext()}
        >
          <FontAwesomeIcon icon={faChevronRight} color="white" />
        </div>
      </div>
    ),
    customPaging: (i) => <div className="dot"></div>,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          adaptiveHeight: true
        }
      }
    ]
  }

  return (
    <div className="quote-slider__container">
      <Slider ref={sliderRef} {...settings}>
        {quotes.map((quote, i) => (
          <React.Fragment key={i}>
            <QuoteCard
              quote={quote.quote}
              name={quote.name}
              image={quote.imageId}
            />
          </React.Fragment>
        ))}
      </Slider>
    </div>
  )
}

export default QuoteSlider
